.react-calendar {
    border: none;
    width: 100%;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
}
.react-calendar__navigation__label {
    border: none;
    outline: none;
    background-color: transparent;
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
}

.react-calendar__navigation__arrow {
    border: none;
    background: transparent;
    outline: none;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__month-view__weekdays__weekday {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #93949f;
    text-transform: capitalize;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    flex: none !important;
}

.react-calendar__month-view__weekdays__weekday:nth-child(7n) {
    margin-right: 0;
}

abbr[title] {
    text-decoration: none;
}
.react-calendar__tile {
    background: #f6f7f8;
    border: none;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
}

.react-calendar__month-view__days__day {
    flex: initial !important;
    width: 36px;
    height: 36px;
    color: #292527;
    margin-right: 6px;
    margin-bottom: 6px;
}

.react-calendar__month-view__days__day:nth-child(7n) {
    margin-right: 0;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #c5c6cb;
    background: #ffffff;
}

.react-calendar__tile--range {
    border: 1px solid #c5c6cb;
    background-color: #fff;
}

.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
    flex: unset !important;
    margin-bottom: 6px;
    margin-right: 6px;
    width: 90px;
    height: 60px;
}

.react-calendar__year-view__months__month:nth-child(3n),
.react-calendar__decade-view__years__year:nth-child(3n) {
    margin-right: 0;
}

.react-calendar__month-view__days__day:disabled {
    color: #c5c6cb;
    background: #ffffff;
}
